/* eslint-disable @next/next/no-img-element */
import { css } from "@nju/css";
import { Logo } from "@nju/feature-portal-header";
import { Footer } from "@nju/feature-portal-footer";
import { BlockContent, Box, Heading } from "@nju/ui";
import { portalRoutes } from "@nju/routes";

export default function MaintenanceModePage() {
  return (
    <Box className={styles.wrapper()} display="flex" flexDirection="column">
      <BlockContent spacing="m">
        <Logo href={portalRoutes.index.get()} />
      </BlockContent>
      <div className={styles.content()}>
        <BlockContent>
          <Box
            align="center"
            display="flex"
            flexDirection={{
              "@onlyMobile": "column",
            }}
            justify={{
              "@fromTablet": "spaceBetween",
            }}
          >
            <Box
              className={styles.textWrapper()}
              display="flex"
              flexDirection="column"
            >
              <Heading
                className={styles.heading()}
                tone="secondary"
                variant="h1"
              >
                UPS
              </Heading>
              <p className={styles.text()}>strona nie została odnaleziona</p>
            </Box>
            <div className={styles.imgWrapper()}>
              <img
                alt="pipik_maintenance"
                src="/images/pipik_no_internet.svg"
              />
            </div>
          </Box>
        </BlockContent>
      </div>
      <Footer />
    </Box>
  );
}

const styles = {
  textWrapper: css({
    flex: "1",
  }),
  wrapper: css({
    minHeight: "100vh",
    background:
      "linear-gradient(50deg, $cerulean 0%, $cerulean 60%, $brightCerulean 60%, $brightCerulean 100%)",
  }),
  imgWrapper: css({
    flex: "1",
    img: {
      maxWidth: "100%",
      "@onlyMobile": {
        mt: "$30",
        maxHeight: "250px",
      },
    },
  }),
  heading: css({
    lineHeight: "1.3",
    "@onlyMobile": {
      fontSize: "20px",
      mb: "$15",
    },
  }),
  text: css({
    color: "$white",
    fontSize: "32px",
    lineHeight: "1.4",
    "@onlyMobile": {
      fontSize: "20px",
    },
  }),
  content: css({
    flex: "1",
    alignItems: "flex-end",
    display: "flex",
  }),
};
