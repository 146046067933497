import { styled } from "@nju/css";

export type BoxProps = React.ComponentProps<typeof Box>;

export const Box = styled("div", {
  variants: {
    display: {
      flex: {
        display: "flex",
      },
      inline: {
        display: "inline",
      },
      none: {
        display: "none",
      },
    },
    flexWrap: {
      wrap: {
        flexWrap: "wrap",
      },
    },
    flexDirection: {
      row: {
        flexDirection: "row",
      },
      column: {
        flexDirection: "column",
      },
      columnReverse: {
        flexDirection: "column-reverse",
      },
    },
    justify: {
      start: {
        justifyContent: "flex-start",
      },
      center: {
        justifyContent: "center",
      },
      spaceBetween: {
        justifyContent: "space-between",
      },
      flexEnd: {
        justifyContent: "flex-end",
      },
    },
    align: {
      center: {
        alignItems: "center",
      },
      start: {
        alignItems: "flex-start",
      },
      end: {
        alignItems: "flex-end",
      },
    },
    border: {
      none: {
        border: "none",
      },
      default: {
        border: "2px solid $geyser",
      },
      info: {
        border: "2px solid $cerulean",
      },
    },
  },
});
